/* @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&family=Roboto:wght@300;400;500&display=swap'); */
:root{
    --pranshu-border:2px rgb(155, 155, 155) solid; 
    --pranshu-color:rgb(69, 117, 248);
    --pranshu-bg-color:rgb(255, 255, 255);
    --font-style:Lora, serif;
    --primary-color:rgb(4, 23, 34);
    --typing-color:rgb(255, 255, 255);
    --content-color:rgb(7, 7, 156);
    --blog-color:black;
    --blog-link:rgb(247, 0, 255);
    --color-size:15px;
}
.light{
    --pranshu-border:2px rgb(49, 44, 44) solid; 
    --pranshu-color:rgb(255, 255, 255);
    --pranshu-bg-color:rgb(87, 155, 32);
    --font-style:Lora, serif;
    --primary-color:rgb(240, 241, 161);
    --typing-color:rgb(20, 95, 138);
    --blog-color:white;
    --blog-link:rgb(142, 23, 197);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-style);
}

body{
    background-color: var(--primary-color);
    transition: all 500ms ease-in-out;
    margin:0px;
    padding:0px;
    
}
.pranshu{
    height: 0px;
    display: flex;
    position: fixed;
    top:0;
    left:0;
    width: 3rem;
    margin-left:0.6rem;
    flex-direction: column;
    /* clip-path: circle(0% at 3.5% 5.5%);   */
    background-color:yellow;
    justify-content: flex-end;
}
.display{
    height: 95vh;
    display: flex;
    /* clip-path: circle(100% at 3.5% 5.5%);   */
   background-color: transparent;
    position:fixed;
    z-index:3;
    width: 3rem;
    /* transition: clip-path 500ms ease-in-out; */
    margin-left:0.6rem;
    flex-direction: column;
    justify-content: center;
}
.pranshujain{
    

    color:var(--pranshu-color);
    width:3rem;
    /* margin-top: 30px; */
    /* height: 100vh; */
    border-top: var(--pranshu-border);
    text-transform: capitalize;
    background: var(--pranshu-bg-color);
    border-right: 1px solid;
    border-left: 1px solid;
    
    cursor: pointer;
    transition: all 500ms ease-in-out;
}

.pranshujain >div{
    font-size: 2rem;
    padding:2px 0px;
    text-align: center;
    border-bottom: 1px rgb(155, 155, 155) solid;
}
.pranshujain >div:hover{
    color:var(--pranshu-bg-color);
    background-color: var(--pranshu-color);
}
/* Navigation design */
.plus{
        position: fixed;
        top:10px;
        left:10px;
        z-index: 4;
    }
    @keyframes icon-color-change{
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(135deg);
        }
    }
    .plus img{
      /* background-color: var(--pranshu-color); */
        background-color: white;
        border-radius: 50%;
    }

.animateIcon img{
animation:icon-color-change 600ms;
animation-iteration-count: 1;
animation-direction: alternate;
animation-fill-mode: both;
}

.menu{
    position: fixed;
    top:0;
    left:0;
    display:grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 90% auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--pranshu-bg-color);  
    clip-path: circle(0% at 3.5% 5.5%);  
    z-index:2;
    transition:clip-path 500ms ease-in-out;
    padding-top:15%;
    padding-bottom:15%;

}

.menudisplay{
    clip-path:circle(1000px);
    background-color: var(--pranshu-bg-color);
}
.menu >ul{
    height: 100%;
    text-decoration: none;
    list-style-type: none;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: flex-end; */
    align-items: flex-end;
    border-right: 1px black solid;
}
.menu >ul >li{
    /* width: 100%; */
    text-align: center;
    padding:1.1rem;
    transition: all 500ms ease-in-out;
}

.menu >ul >li >a{
    color:var(--pranshu-color);
    text-decoration: none;
    font-size: 1.8rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    transition: all 500ms ease-in-out; 
}
.menu a:hover{
    /* transition: all 500ms ease-in-out; */
    text-decoration: line-through;
    
}
.profilelink{
    display:flex;
    margin-right: 10px;
    height: 100%;
    /* padding-top: 5%; */
    padding: 10px;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    align-content: flex-end;
    align-items: flex-end;
}

.swipe-up{
    margin-top: -10%;
    color:white;
    font-size: 4rem;
    text-align: center;
    margin: auto;
    padding:20px;
    margin-top: -8%;
    transform: rotate(180deg);
    animation: animationswipe 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.typing{
    color: var(--typing-color);
}
#cursor {
    border-left: 0.2rem solid var(--pranshu-bg-color);
    animation: blink .7s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
#info{
    font-size: 1.3rem;
    border-radius: 20%;
    background-color: white;
    color:rgb(19, 86, 185);
    font-family: fantasy;
    padding:35px;
    margin: 20px; 
}
.typing{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-transform: uppercase;
    color:var(--typing-color)
}

#introduction{
    margin:20px;
    padding:20px;
    color:white;
    margin-top:50px;
    /* font-size: 3rem; */
    /* height:90vh; */
    /* justify-content: center; */
    /* align-items: center; */
    /* flex-direction: column; */
    font-family: monospace;
    color:var(--pranshu-bg-color)
}
#introduction >div:first-child{
    font-size:30px;
    display:flex;
    flex-direction:row;
    font-family:monospace;
    justify-content:center;
    align-items:center;
}
#introduction div{
    font-family:monospace;
}
#introduction .iam{
    /* font-size: 30p; */
    padding:10px;
    margin-left:10px;
    background:white;
    border-radius: 50px;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    color:var(--content-color);
    cursor: pointer;
    text-align: center;
    font-family:monospace;
}
#info >div{
    padding:10px;
    color:var(--content-color);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
}
a,button {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
/* BLog editing */
.blogs{  
    margin:20px;
    margin-top: 80px;
}
.blogs h1{
    font-family: monospace;
}
.blog{
    margin:20px;
    padding:20px;
    border:2px black solid;
    border-radius:20px;
    background-color:var(--pranshu-bg-color);
    color:var(--blog-color);
    font-family: monospace;
}
.blog .title{
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    justify-content: space-between;
    padding:10px;
    font-family: monospace;
}
.blog .title *{
    font-family: monospace;
    font-size: 23px;
}
.blog .date{
    text-decoration:underline;
    font-size:15px;
}


.blog .description{
    padding:10px;
    margin-top:10px;
    font-family: monospace;
    font-size: 18px;
}
.blog .description div{
    font-family: monospace;
}

.blog .description a{
    color:var(--blog-color);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: monospace;
    font-size: 15px;
}
.theme-icon{
    width:30px;
    height:30px;
    margin-right: 5px;
    margin-left:10px;
}
.blog-head{
    color:var(--pranshu-bg-color);
    margin:40px;
    text-align: center;
    text-decoration: underline;
    
}
.contact-outer{

    width:100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-content: center;
}
.contact-form{
    width:40%;
    padding:20px;
    margin:auto;
    display:flex;
    flex-direction: column;
    background-color: white;
    border-radius:30px;
    border:1px black solid;
    justify-content: center;
    align-items: center;
}
.contact-form div{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-form *{
    padding:5px;
}
.contact-form input{
    border-radius:20px;
}
.contact-form textarea{
    border-radius:20px;
}
.fullblog{
    background-color:white;
    padding:10px;
    padding-top:60px;
    border:2px solid black;
}
.fullblog .head{
    padding:10px;
    margin:10px;
    width:100%;
    /* border:black solid 1px; */
    text-align:center;
    font-size:2.5rem;
}
.head{
    text-align: center;
    /* background-color: black; */
}
.fullblog .story{
    padding:20px;
    margin:10px;
    /* border:black solid 1px; */
    font-size:1.3rem;
    line-height: 2rem;
    font-family: 'Courier New', Courier, monospace;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-family: monospace;
}
.fullblog .comments{
    padding:20px;
    
}
.fullblog .comments .comment{
    list-style: none;
    padding-top:5px;
    font-size:1.2rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding:10px;
}

.fullblog .chead{
    font-size:1.6rem;
    padding:20px;
    /* padding:10px 30px; */
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    text-decoration: underline;
}
.idk2{
    height: 100vh;
    direction: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:60px;
    background-color: white;
}
.idk2 h2{
    color: aqua;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    background:linear-gradient(#000000,#000000c0,#0000009b);}
.idk{
    margin:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.idk label{
    text-align: center;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
}
.idk form{
    width:50%;
    margin:auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.idk form input{
    padding:10px;
    margin:10px;
}
.idk button{
    font-size: 20px;
    color:white;
    font-family: 'Courier New', Courier, monospace;
    background-color: goldenrod;
}
.idk div{
    text-align:center;
}
.readfullblog{
    font-family:monospace;
    display: block;
    padding-left: 15px;
    padding-top:15px;
    font-size: 17px;
    color:var(--primary-color);
}
.pleasewait{
    display:flex;
    align-self:center;
    justify-content: center;
    align-items:center;
    width:100%;
    height:100vh;
    font-size:20px;
    text-align:center;
    padding-top:50px;
    font-family:monospace;
    color:var(--pranshu-bg-color);
}

bl{
font-size:var(--color-size) !important;
   color:#2199de;
    display:block;
    background-color:black;
    background-color:white;    
}
g{
    font-size:var(--color-size) !important;
    color:#21deac;
    display:block;
    background-color:white;
    /* width:200px; */
    /* min-width:200px;
    margin-right:auto; */
}
pi{
    font-size:var(--color-size) !important;
    color:#e344ce;
    display:block;
    background-color:white;
}
o{
    font-size:var(--color-size) !important;
    color:#dba032;
    display:block;
    background-color:white;
}
.myintro{
    background:var(--pranshu-bg-color);
    display:flex;
    color:var(--primary-color);
    width:70%;
    margin:auto;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin-top:30px;
    border-radius: 70px;
    margin-bottom:50px; 
}

.photostyle{
    display:block; 
    width:140px;
    border-radius: 50%; 
    border:5px solid white;
}

.myintro .mydata{
    padding-top:20px;
    padding-left:30px;
    font-size:20px;
    padding-bottom:20px;
}
.myint{
    /* padding-top:40px; */
    
    /* display:block !important; */
    background:var(--pranshu-bg-color);
    color:var(--primary-color);
    width:70%;
    margin:auto;
    margin-top:30px;
    border-radius: 70px; 
    padding:12px;
    padding-bottom:17px;
}
.myint .head{
    font-size:22px;
    padding-left:20px;
    padding-bottom:10px;
    border-bottom:var(--pranshu-border);
}
.myint .info{
    padding-top:10px;
}
.myint .info ul{
    font-size:20px;
    display:flex;
    justify-content:center;
}
.myint ul{
    list-style:none;
    text-decoration:none;
}
.myint ul li{
    color:white;
    padding:0px 10px;
}
.myint ul li a{
    padding:7px;
    border-radius:20px;
    /* text-decoration:none; */
    /* background:black; */
    color:var(--primary-color);
    font-family:monospace;
}
img{
    display:block;
    max-width: 500px;
    /* min-width:500px; */
}
@media ( max-height: 606px ){
    .pranshu{
        display: none;
    }
    .menu{
        margin:0px;
        padding:0px;
    }
    .display{
        display:none;
    }
       
}
@media (max-width:800px)
{
    #introduction >div:first-child{
        flex-direction:column;

    }
    .myintro{
        flex-direction:column;
        width:100%;
        border-radius:70px 70px 0px 0px;

    }   
    .myintro img{
        border-color:black;
        border-width:2px;
        margin-top:2px;
        margin-bottom:2px;
    }
    .myintro .mydata{
        border-top:var(--pranshu-border);
        padding-bottom:20px;
        font-size:16px;
        padding-left:0px;
        padding:10px;
        /* padding-right:19px;
        padding-left:19px; */
    }
    #introduction div{
        flex-direction:column;
    }
    .myint{
        /* padding:5px; */
        width:100%;
        border-radius:20px;
    }
    .myint *{
        padding:0px;
    }
    .myint >.info{
        display:flex !important;
        flex-direction:row !important;
        justify-content:center;
        align-items:center;
    }
    .myint img{
        padding:0px;
        margin:0px;
    }
    .blog{
        margin-left:0px;
        /* padding-left:0px; */
        padding:5px;
        width:100%;
    }
    .blog .date{
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
        font-size:15px;

    }
    .myint .info ul:first-child{
        border-right:var(--pranshu-border);
    }
    .myint .info ul{
        flex-direction:column;
        /* justify-content:center;
        align-items:center; */
    }
    .myint .info ul li{
        padding:10px;
    }
}
